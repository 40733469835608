import React from "react"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

//Image
import MapImg from "../images/map.png"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="Have a question about cloud computing and cloud services? Contact Metsi today."
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div
        className="contact-section"
        id="contact"
        style={{ paddingTop: "120px" }}
      >
        <div className="outer-container">
          <h2>Contact Us</h2>
          <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
            <h1 style={{ textAlign: "center" }}>
              Have a question about our solutions?
            </h1>
          </ScrollAnimation>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default contact
